import React, { useState } from 'react';
import styled from 'styled-components'
const sentences = ['This library provides its users two main sets of solutions: the Downshift component and a set of hooks. The component is still the main part of the library, providing the autocomplete/combobox logic as a render prop. The hooks are newer and are going to be the way forward to providing accessibile experiences. Right now we support useSelect for select components, useCombobox for combobox/autocomplete inputs and useMultipleSelection to make the selection of multiple items easier for both the select and combobox elements.',
  'Mock functions allow you to test the links between code by erasing the actual implementation of a function, capturing calls to the function (and the parameters passed in those calls), capturing instances of constructor functions when instantiated with new, and allowing test-time configuration of return values.',
  'Once you’ve defined schemas for User and Task, Realm automatically generates GraphQL types and resolvers that allow you to access data through the GraphQL API. By default, however, all GraphQL queries from a client application will fail.To allow users to read and write data, you must first define data access rules that determine whether a user has read or write permissions for a given document.']
const Button = styled.button`
  font-size: 2rem; 
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  #border: 2px dashed red;
`;
// const Span = styled.span` //<---- span의 onClick에서 e.target.name 못 읽어서 사용안함.
// padding: 10px;
// `;
const Card = ({ sentences, init }) => {
  const [pos, setPos] = useState(init || Array(sentences.split(' ').length).fill("b"))
  const [hide, setHide] = useState(false)
  const handleClick = (idx) => {
    let pos1 = [...pos]
    pos1[idx] = pos1[idx] === 'b' ? 'w' : 'b'
    setPos(pos1)
  }
  const handleHide = () => {
    setHide(!hide)
  }
  return (
    <div className="card" style={{ fontSize: '3rem', width: "auto" }}>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <h5 className="card-title">Card title</h5>
            <button className='btn btn-success ml-3' onClick={handleHide}>{hide ? '감추기' : '보여주기'}</button>  {/* todo: 눈 아이콘 사용하자. */}
          </div>
        </div>
        <div style={{ lineHeight: '2rem', padding: '0rem' }}>
          {sentences.split(' ').map((w, idx) => {
            return (
              <Button
                className='btn'
                key={idx}
                name={idx}
                value={w}
              >
                {check({ w, idx, pos, handleClick, hide })}
              </Button>)
          })}
          <div style={{ fontSize: '8px', lineHeight: '8px' }}>{JSON.stringify(pos)}</div>
        </div>
        <a href="#" className="btn btn-primary">Go somewhere</a>
      </div>
    </div>
  );
}

const check = ({ w, idx, pos, handleClick, hide }) => {
  let n = w.indexOf(',')
  n = n < 0 ? w.indexOf('.') : n
  return n >= 0
    ? <><span name={idx} style={border(pos[idx], hide)} onClick={() => handleClick(idx)}>{w.slice(0, n)}</span><span style={{ color: 'black' }}>{w.slice(n)}</span></>
    : <span name={idx} style={border(pos[idx], hide)} onClick={() => handleClick(idx)}>{w}</span>
}

const border = (color, hide) => {
  const color1 = hide ? 'black' : 'white'
  return color === 'w'
    ? { color: color1, border: '2px solid red', borderRadius: '20px' }
    : { color: 'black' }
}
export default Card;
