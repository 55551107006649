import React from 'react';
import Card from './card';

function App() {
  return (
    <div className="App">
      <Card sentences={'This library provides its users two main sets of solutions: the Downshift component and a set of hooks. The component is still the main part of the library, providing the autocomplete/combobox logic as a render prop. The hooks are newer and are going to be the way forward to providing accessibile experiences. Right now we support useSelect for select components, useCombobox for combobox/autocomplete inputs and useMultipleSelection to make the selection of multiple items easier for both the select and combobox elements.'}
        init={["b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "w", "w", "w", "w", "b", "b", "w", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b"]}
      />

      <Card sentences={'Mock functions allow you to test the links between code by erasing the actual implementation of a function, capturing calls to the function (and the parameters passed in those calls), capturing instances of constructor functions when instantiated with new, and allowing test-time configuration of return values.'}
        init={["b", "b", "b", "b", "b", "b", "b", "w", "b", "w", "b", "w", "b", "b", "w", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "w", "b", "b", "b", "b", "w", "w", "b", "b", "w", "b", "w", "b", "b", "b"]
        } />
      <Card sentences={'Once you’ve defined schemas for User and Task, Realm automatically generates GraphQL types and resolvers that allow you to access data through the GraphQL API. By default, however, all GraphQL queries from a client application will fail.To allow users to read and write data, you must first define data access rules that determine whether a user has read or write permissions for a given document.'}
        init={["w", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "w", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "b", "b", "b", "w", "w", "w", "b", "b", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "b", "w", "b", "b", "w", "b"]
        } />
      <Card sentences={"MongoDB Realm provides a first-class service interface for MongoDB Atlas that lets you securely query one or more Atlas clusters. You can use standard MongoDB query language syntax to access your data directly in your client application code or from a Realm Function. The MongoDB service secures your data with a dynamic, role-based Rules engine that proxies and modifies incoming queries based on rules that you define. There are three types of MongoDB collection rules: roles, filters, and Realm Schema."}
        init={["b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "w", "b", "b", "w"]} />
      <Card sentences={"Ahchoo! That was a big sneeze. Ahchoo! Ahchoo! You can't stop sneezing. Your nose is runny. Your throat is sore. Your stomach aches, too. It's no fun. You feel terrible. Some germs can make your body sick. Sometimes your body fights the germs. Then, you have a fever and your body temperature rises. Many illnesses, like colds and flu, can spread from one person to another. Do you have a friend with a cold? His or her cold germs can get into your body through your nose and mouth. Then, in a few days, you may catch that cold, too. Do you have a cold yourself? Don't spread your germs to your family or your friends. Sneezes and coughs can spread your germs. So cover your face and sneeze or cough. Use tissues and blow your nose. Wash your hands very often. Sometimes you get sick and go to a doctor in a clinic. The doctor may check your temperature. He may look down your throat and listen to your heart. He may say, 'You have a cold. You should get some rest and take a hot bath.' He may give you some medicine. Sometimes the doctor may give you a shot. You may not like it, but be brave. Your body will fight the germs, and you will feel better soon."}

        init={["b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "w", "b", "b", "b", "b", "w", "b", "b", "b", "w", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "w", "b", "b", "b", "w", "b", "b", "w", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "w", "b", "w", "w", "b", "b", "w", "b", "b", "b", "b", "b", "w", "w", "w", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "w", "w", "w", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "w", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "w", "w"]}
      />

      <Card sentences={'This function will be passed two parameters: an object containing a tokenId, token, username, and password, and any other arguments that the client SDK reset function is called with. Selecting a new function will allow you to edit a provided default function. Learn more about writing a reset password function.'}
        init={["b", "b", "b", "w", "w", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "w", "b", "b", "b", "b"]}
      />
      <Card sentences={'This function will be passed two parameters: an object containing a tokenId, token, username, and password, and any other arguments that the client SDK reset function is called with. Selecting a new function will allow you to edit a provided default function. Learn more about writing a reset password function.'}
        init={["b", "b", "b", "w", "w", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "w", "w", "w", "b", "b", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "w", "b", "b", "b", "b"]}
      />
      <Card sentences={'For any given task, a user may be the task’s assignee. We want users to be able to create, modify, and delete tasks for themselves. They should be able to see other users’ tasks but shouldn’t be allowed to create new tasks for other users or delete tasks that don’t belong to them. To accomplish this, we’ll define two roles for the tasks collection. The isAssignee role applies to tasks where the user’s id is listed in the document’s asignee field and the default role applies to all other tasks. In the rules editor, select the tracker.tasks collection and then click the Permissions tab. Define two roles for the collection that match the following specifications:'}
        init={["b", "w", "b", "b", "b", "b", "w", "w", "b", "b", "w", "b", "b", "b", "w", "w", "w", "w", "b", "b", "b", "b", "b", "b", "b", "b", "b", "w", "w", "w", "b", "b", "b", "b", "b", "w", "w", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "w", "w", "w", "b", "b", "w", "b", "b", "b", "b", "w", "b", "b", "b", "w", "b", "w", "b", "w", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "w", "b", "w", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "b", "b", "b", "b", "b", "w", "b", "b", "b", "w", "b", "b", "b", "b", "w", "b", "b", "w"]}
      />
    </div>
  );
}

export default App;

// 
